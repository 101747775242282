const firebaseConfig = {
  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // databseURL: process.env.REACT_APP_DATABASE_URL,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messageSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  // appId: process.env.REACT_APP_APP_ID,
  apiKey: "AIzaSyCxZQzTQg_Nselj_gi6eFSWlltT0r22Euo",
  authDomain: "xarothi-3010a.firebaseapp.com",
  databaseURL: "https://xarothi-3010a.firebaseio.com",
  projectId: "xarothi-3010a",
  storageBucket: "xarothi-3010a.appspot.com",
  messagingSenderId: "576056012448",
  appId: "1:576056012448:web:d445cf37180b270ba1a3c5",
  measurementId: "G-FM4YB7H05Y"
};

const getEnvironmentConfig = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'staging':
      return {
        baseUrl: 'https://api.xarothi.in/',
        // baseUrl: 'https://api-v3.xarothi.in/',
        firebaseConfig,
      };
    case 'prod':
      return {
        baseUrl: 'https://api.xarothi.in/',
        // baseUrl: 'https://api-v3.xarothi.in/',
        firebaseConfig,
      };
    default:
      return {
        // baseUrl: 'http://127.0.0.1/',
        baseUrl: 'https://api.xarothi.in/',
        // baseUrl: 'https://api-v3.xarothi.in/',
        // baseUrl: 'http://139.59.71.173/',  // Server 1
        // baseUrl: 'http://139.59.72.161/',  // New IP Server 2
        firebaseConfig,
      };
  }
};

export const BASE_URL = getEnvironmentConfig().baseUrl;
export const FIREBASE_CONFIG = getEnvironmentConfig().firebaseConfig;
export const API_REQUEST_TIMEOUT = 30000;
import { combineReducers } from "redux";
// import categories from "./Categories/Reducer";
// import products from "./Products/Reducer";
import networkActivity from "./NetworkActivity/Reducer";
// import cart from "./Cart/Reducer";
import user from "./User/Reducer";
import alert from "./Alert/Reducer";
// import wishList from "./WishList/Reducer";

const appReducer = combineReducers({
  networkActivity,
  user,
  alert
  // categories,
  // products,
  // cart,
  // orders,
  // wishList,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: any, action: any) => appReducer(state, action);

export default rootReducer;
class StorageService {
  private static instance: StorageService;
  private data: any = {};
  private localStorageAvailable: boolean = false;

  constructor() {
    try {
      this.localStorageAvailable = true;
    } catch (error) {}
  }

  public static getInstance() {
    if (!StorageService.instance) {
      StorageService.instance = new StorageService();
    }
    return StorageService.instance;
  }

  get(key: string) {
    if (this.localStorageAvailable) {
      const dataFromLocalStorage = localStorage.getItem(key);
      if (dataFromLocalStorage && dataFromLocalStorage !== 'undefined' && dataFromLocalStorage !== 'null') {
        return JSON.parse(dataFromLocalStorage);
      } else {
        this.remove(key);
      }
      return null;
    }
    return this.data[key];
  }

  set(key: string, data: any) {
    if (this.localStorageAvailable && data !== 'undefined' && data !== 'null' && data !== undefined && data !== null) {
      localStorage.setItem(key, JSON.stringify(data));
    }
    this.data[key] = data;
  }

  remove(key: string) {
    if (this.localStorageAvailable) {
      localStorage.removeItem(key);
    }
    delete this.data[key];
  }
}

const storageServiceInstance = StorageService.getInstance();
export { storageServiceInstance as storageService };

import axios, { AxiosInstance } from 'axios';
import { BASE_URL, API_REQUEST_TIMEOUT } from '../constants/environment.constant';
import { storageService } from '../services';
import { authService } from './authService';
import { linearLoaderService } from './linearLoaderService';

export class AxiosService {
  private publicAxiosInstance: AxiosInstance;
  private privateAxiosInstance: AxiosInstance;

  constructor() {
    this.publicAxiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: API_REQUEST_TIMEOUT,
    });

    this.privateAxiosInstance = axios.create({
        baseURL: BASE_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storageService.get('accessToken')}`,
        },
        timeout: API_REQUEST_TIMEOUT,
    });

    this.privateAxiosInstance.interceptors.request.use((config: any) => {
      linearLoaderService.showLoader();
      return config;
    });

    this.privateAxiosInstance.interceptors.response.use((response: any) => {
      linearLoaderService.hideLoader();
      // console.log("===========>", {response})
      return response;
    }, (error) => {
      linearLoaderService.hideLoader();
      const { response: { data: { code = null, message = '' } = {} } = {} } = error;
      if (code === 401 && message.includes('expired')) {
        authService.signOut();
      }
      return Promise.reject(error);
    });

    this.publicAxiosInstance.interceptors.request.use((config: any) => {
      linearLoaderService.showLoader();
      return config;
    });

    this.publicAxiosInstance.interceptors.response.use((response: any) => {
      linearLoaderService.hideLoader();
      return response;
    }, (error) => {
      linearLoaderService.hideLoader();
      return Promise.reject(error);
    });
  }

  public setAuth(isAuth = false) {
    if (isAuth) return this.privateAxiosInstance;
    return this.publicAxiosInstance;
  }

  public withToken({accessToken, refreshToken}: any) {
    return axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        refreshToken,
      },
      timeout: API_REQUEST_TIMEOUT,
    });
  }

  public withoutToken() {
    return axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: API_REQUEST_TIMEOUT,
    });
  }
}

const axiosServiceInstance = new AxiosService();
export { axiosServiceInstance as axiosService };

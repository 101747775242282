import logo from "../../assets/images/logo.png";
import axios from 'axios';
import { BASE_URL } from "../constants/environment.constant";

declare var window: any;

const loadScript = (src: any) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export function initPaymentRazorpay(
  totalAmount: number,
  user: any
) {
  return new Promise(async (resolve, reject) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      reject("Razorpay SDK failed to load. Are you online?");
    }

    // creating a new order
    const result: any = await axios.post(
      `${BASE_URL}user/user/subscription`,
      {
        amount: Number((totalAmount * 100).toFixed(0)),
        receipt: "receipt-" + Date.now().toString(),
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return Promise.reject();
    }

    const { amount, id: order_id, currency } = result.data;

    const options = {
      // key: 'rzp_test_0wWm4MAgx95xyE', // Razorpay test key
      key: 'rzp_live_ANO3RVlLwq8lRi', // Razorpay live key
      amount,
      currency,
      name: "Xarothi Enterprise",
      description: 'Subscription',
      image: logo,
      order_id,
      handler: async (response: any) => {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        console.log('===============>', data);  
        resolve(response);
      },
      prefill: { 
        contact: user.mobile,
        email: user.email,
        name: user.name,
      },
      notes: {
        address: "Xarothi Enterprise",
      },
      theme: {
        color: "#03A9F4",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  });
}

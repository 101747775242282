import React from 'react';
import { Container, IconButton, Link } from '@material-ui/core';
import logo from '../../../assets/images/logo.png';
import { useHistory } from 'react-router-dom';
import { HOME_ROUTE } from '../../constants/route.constant';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import './styles.scss';

export const Footer = () => {

  const history = useHistory();

  const handleLogoClick = () => {
    history.push(HOME_ROUTE.path);
  }

  return (
    <div className='footer-section'>
      <Container>
        <div className='logo-holder'>
          <img onClick={handleLogoClick} className="img-logo" src={logo} alt="logo" />
          <div className="img-name">Xarothi, Your Career Companion</div>
        </div>

        <div className='links-holder'>
          <Link target="_blank" href="about-us">About Us</Link>
          <Link target="_blank" href="privacy-policy">Privacy Policy</Link>
          <Link target="_blank" href="terms-and-conditions">Terms and Conditions</Link>
          <Link target="_blank" href="contact-us">Contact Us</Link>
          <Link target="_blank" href="work-with-us">Work with us</Link>
          <Link target="_blank" href="refund-and-cancellation">Refund and Cancellation policies</Link>
        </div>

        <div className="social-media-links">
          <IconButton aria-label="delete" size="medium">
            <FacebookIcon fontSize="inherit" className="social-icon" />
          </IconButton>
          <IconButton aria-label="delete" size="medium">
            <InstagramIcon fontSize="inherit" className="social-icon" />
          </IconButton>
          <IconButton aria-label="delete" size="medium">
            <TwitterIcon fontSize="inherit" className="social-icon" />
          </IconButton>
          <IconButton aria-label="delete" size="medium">
            <LinkedInIcon fontSize="inherit" className="social-icon" />
          </IconButton>
        </div>

        <div className="all-rights-reserved">
          © 2022 Xarothi. All rights reserved.
        </div>
      </Container>
    </div>
    
  )
}
import firebase from "firebase";
import { FIREBASE_CONFIG } from "../constants/environment.constant";
import { CAPTCHA_CONTAINER_ID } from "../constants/app.constant";
import { storageService } from "./storageService";
import { axiosService } from ".";

export class AuthService {
  public firebaseAuth: any = null;
  public firebaseDB: any = null;
  public appVerifier: any = null;

  constructor(config: any) {
    firebase.initializeApp(config);
    this.firebaseAuth = firebase.auth;
    this.firebaseDB = firebase.database();
  }

  signInWithGoogle = () => {
    const provider = new this.firebaseAuth.GoogleAuthProvider();
    return this.firebaseAuth().signInWithPopup(provider);
  };

  signInWithFacebook = () => {
    const provider = new this.firebaseAuth.FacebookAuthProvider();
    return this.firebaseAuth().signInWithPopup(provider);
  };

  sleep = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  handlePostAuthentication = async ({accessToken, refreshToken }: any = {}) => {
    try {
      const {data: response}: any = await axiosService.withToken({accessToken, refreshToken}).post('user/user/login');
      this.authSuccess({accessToken: response.data.accessToken});
    } catch (error) {
      console.error("Error in authenticating!");
    }
    
  }
  authSuccess = ({accessToken, refreshToken }: any = {}) => {
    storageService.set('accessToken', accessToken);
    // storageService.set('refreshToken', refreshToken);
  }

  signOut = () => {
    storageService.remove('accessToken');
    storageService.remove('refreshToken');
    storageService.remove('name');
    storageService.remove('lastRequested');
    return this.firebaseAuth().signOut();
  };

  signInWithPhoneNumber = (
    phoneNumber: string,
    successCallback: any,
    errorCallback: any
  ) => {
    if (!this.appVerifier) {
      const globalWindow: any = window;
      globalWindow.recaptchaVerifier = new this.firebaseAuth.RecaptchaVerifier(
        CAPTCHA_CONTAINER_ID,
        // {
        //   size: "invisible",
        // }
      );
      this.appVerifier = globalWindow.recaptchaVerifier;
    }
    
    this.firebaseAuth()
      .signInWithPhoneNumber(phoneNumber, this.appVerifier)
      .then(successCallback)
      .catch(errorCallback);
  };
  
  requestOtp = async ({ mobile }: any) => {
    try {
      const { data: response }: any = await axiosService.withoutToken().post('user/auth/requestOtp', { mobile });
      return response
    } catch (error) {
      console.error("Error in authenticating!");
    }
  }

  verifyOtp = async ({ mobile, otp, otpToken }: any) => {
    try {
      const { data: response }: any = await axiosService.withoutToken().post('user/auth/verifyOtp', { mobile, otp, otpToken });
      return response
    } catch (error) {
      console.error("Error in authenticating!");
      return { error }
    }
  }

  verifyJwt = async () => {
    try {
      const { data: response }: any = await axiosService.setAuth(true).post('user/auth/verify');
      return response
    } catch (error) {
      console.error("JWT invalid!", error);
      return null;
    }
  }

  get isLoggedIn() {
    const token = storageService.get('accessToken');
    return !!token;
  }

  get userName() {
    const name = storageService.get('name');
    return name;
  }
}
const authServiceInstance = new AuthService(FIREBASE_CONFIG);

export { authServiceInstance as authService };

import { createTheme } from "@material-ui/core";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#03A9F4" // This is an blue looking color
        },
        secondary: {
            main: "#F44E03", // Red type or orange
        }
    },
});
import { LINEAR_LOADER_CONTAINER_ID_NAME } from '../constants/app.constant';

export class LinearLoaderService {

  showLoader() {
    const element: any = document.getElementById(LINEAR_LOADER_CONTAINER_ID_NAME);
    element.style.display = "block";
  }
  hideLoader() {
    const element: any = document.getElementById(LINEAR_LOADER_CONTAINER_ID_NAME);
    element.style.display = "none";
  }
}

const linearLoaderServiceInstance = new LinearLoaderService();
export { linearLoaderServiceInstance as linearLoaderService };
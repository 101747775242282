import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  root: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  }
})((props: MenuProps) => (  
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      // backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        // color: theme.palette.common.white,
      },
      
    },
  },
}))(MenuItem);
import React, { Component } from 'react';
import { authService, axiosService } from '../services';
import { storageService } from '../services';
// import { showToast, PopupUtility } from '../utilities';
// import { TOAST_CONTAINER_ID, POPUP_CONTAINER_ID } from '../AppConfig';
// import { LOGGED_IN_MESSAGE, USER_SET_NAME_MESSAGE } from '../AppConstants';

export const withAuth = function(AppComponent: any) {
  return class AuthWrapper extends Component<any, any> {
        state = {
          authenticated: false,
          loading: true,
          userNameStatus: false,
        };
    
        setUserData = async (user: any = {}) => {
          const { displayName: name, phoneNumber, photoURL, refreshToken, Aa: accessToken } = user;
          this.props.setUser({name, phoneNumber, photoURL, accessToken, refreshToken});
          // authService.authSuccess({accessToken, refreshToken}); // Migrated to inbuilt JWT

          // Fetch Profile and load into state
          // this.fetchUserProfile();
        };

        fetchUserProfile = () => {
          axiosService.setAuth(true).get('user/user/profile').then(({data: { data: response}}: any) => {
            storageService.set('name', response.name);
            this.props.setUserProfile({...response});
            this.setUserData(response)
          });
        }
    
        setAuthStatusSuccess = () =>
          this.setState({
            userNameStatus: true,
          });
    
        onAuthSuccess = () => {
          this.fetchUserProfile();

          // this.setUserData(user);
          // if (!user.displayName) {
          //   // showToast(USER_SET_NAME_MESSAGE);
          //   // PopupUtility(SetUserNamePopup, {
          //   //   setUserName: (name: string) => user.updateProfile({ displayName: name }),
          //   // }).then(() => {
          //   //   this.setUserData(user);
          //   //   this.setAuthStatusSuccess();
          //   // });
          // } else {
          //   // showToast(LOGGED_IN_MESSAGE(user.displayName));
          // }

          this.setAuthStatusSuccess();
          this.setState({
            authenticated: true,
            loading: false,
          });
        };
    
        onAuthFailed = () => {
          this.setState({
            authenticated: false,
            loading: false,
          });

          storageService.remove('accessToken');
          storageService.remove('refreshToken');
          storageService.remove('name');
        }

        componentDidMount() {
          // if (window.location.href.includes('localhost')) return;


          // Call new endpoint

          if (storageService.get('accessToken')) {
            
            authService.verifyJwt().then((res: any) => {
              console.log('========> res', res)
              if (res) return this.onAuthSuccess();
              else return this.onAuthFailed();
            });
          }
          
        }
    
        render(): JSX.Element {
          const { loading } = this.state;
          return (
            <AppComponent {...this.state} {...this.props} />
          );
        }
      };
};
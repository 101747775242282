import moment from "moment";

function str_pad_left(val: any, pad: string, length: number) {
  return (new Array(length+1).join(pad)+val).slice(-length);
}


export const formatSecsToMMSS = (time: number) => {
  if (isNaN(time)) return '--:--';
  const hours = Math.floor(time / 3600);
  time = time - hours * 3600;
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  let finalTime = str_pad_left(minutes,'0',2)+':'+str_pad_left(seconds,'0',2);
  if (hours) finalTime = str_pad_left(hours,'0',2)+':' + finalTime;

  return finalTime;
}

export const formatMinsToHHMM = (duration: number) => {
  if (duration < 60) return `${duration} mins`;
  return `${(duration / 60)} hrs`;
} 

export const wasCreatedRecently = (date: any, threshold: number = 30) => {
  if (!date) date = new Date();
  date = moment(new Date(date))
  const diffInDays: number = moment().diff(date, 'days')
  if (Math.abs(diffInDays) <= threshold) return true;
  return false;
}
import { SET_USER, UPDATE_USER, SET_USER_PROFILE } from "./Types";
import { ActionDispatcher } from "../ActionDispatcher/ActionDispatcher";
import { createAction } from '@reduxjs/toolkit';

// export function setUser(user: any) {
//   return ActionDispatcher.getInstance().dispatch({
//     type: SET_USER,
//     user,
//   });
// }

// export function updateUser(user: any) {
//   return ActionDispatcher.getInstance().dispatch({
//     type: UPDATE_USER,
//     user,
//   });
// }

export const setUser = createAction(
  SET_USER,
  function prepare(payload) {
    return {
      payload,
    };
  },
);

export const updateUser = createAction(
  UPDATE_USER,
  function prepare(payload) {
    return {
      payload,
    };
  },
);

export const setUserProfile = createAction(
  SET_USER_PROFILE,
  function prepare(payload) {
    return {
      payload,
    };
  },
);
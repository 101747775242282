import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import logo from '../../../assets/images/logo.png';
import { LoginDialog, SideDrawer, SignupDialog, SubscriptionDialog } from '..';
import { useHistory } from 'react-router-dom';
import { HOME_ROUTE, MOCK_TEST_ROUTE, EXAM_DETAILS_ROUTE, WORK_WITH_US_ROUTE, CONTACT_US_ROUTE, ABOUT_US_ROUTE, OUR_INSTITUTES_ROUTE } from '../../constants/route.constant';
import { Avatar, IconButton } from '@material-ui/core';
import { StyledMenu, StyledMenuItem } from '../StyledMenu/StyledMenu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { authService } from '../../services';
import { RootState } from '../../stores/rootReducer';
import { connect } from 'react-redux';
import { addAlert } from '../../stores/Alert/Actions';
import MenuIcon from '@material-ui/icons/Menu';
import { useDeviceDetector } from '../../hooks/useDeviceDetector';
import clsx from 'clsx';
import './styles.scss';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#ffffff',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbarPadding: {
    paddingLeft: '70px',
    paddingRight: '70px',
  },
  logo: {
    width: '68px',
    cursor: 'pointer',
  },
  userAvatar: {
    background: '#03A9F4',
  },
  dropdownWelcome: {
    padding: '20px',
    borderBottom: 'solid 1px #efefef',
    color: theme.palette.primary.main,
  },
  '&.MuiMenu-list': {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  dropdownMenu: {
    
  },
}));


const Header = ({ 
  showLoginDialog = false,
  loginDialogClosed = () => {},
  showSubscriptionDialog = false,
  subscriptionDialogClosed = () => {},
  addAlert 
}: any) => {
    const classes = useStyles();
    const history = useHistory();
    
    const { isLoggedIn, userName } = authService;
    
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    const [signupDialogOpen, setSignupDialogOpen] = useState(false);
    const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

    const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openSideDrawer, setOpenSideDrawer] = useState(false);
    const { isMobile, isTablet } = useDeviceDetector();

    const handleLogoClick = () => {
      history.push(HOME_ROUTE.path);
    }

    const handleProfileDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
      setProfileAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
      authService.signOut();
      history.push('/')
    }

    const getInitials = (str: string) => {
      const initials = str?.length && str[0].toUpperCase();
      return initials || 'Hi';
    }
    
    const navigate = (url: string) => {
      history.push(url);
    }

    const handleLoginDialogClose = () => {
      setLoginDialogOpen(false);
      loginDialogClosed();
    }

    const handleSubscriptionDialogClose = () => {
      setSubscriptionDialogOpen(false);
      subscriptionDialogClosed();
    }

    const handleSubscriptionDialogOpen = () => {
      setSubscriptionDialogOpen(true);
    }

    useEffect(() => {
      if (showLoginDialog === true) {
        setLoginDialogOpen(true);
      }
      if (showSubscriptionDialog === true) {
        setSubscriptionDialogOpen(true);
      }
    }, [showLoginDialog, showSubscriptionDialog]);

    return (
        <div className={classes.root}>
          <AppBar variant="elevation" color="transparent" position="static">
              <Toolbar className={clsx({[classes.toolbarPadding]: !(isTablet || isMobile)})}>
                
                {
                  (isTablet || isMobile) &&
                  <IconButton edge="start" onClick={() => setOpenSideDrawer(true) } className={clsx(classes.menuButton, 'responsive-menu-btn')} color="primary" aria-label="menu">
                    <MenuIcon />
                  </IconButton>
                }
                
                
                <img onClick={handleLogoClick} className={classes.logo} src={logo} alt="logo" />
                <Typography variant="h6" className={classes.title}>
                </Typography>

                <div className="navigation-links-container">
                  <Button onClick={() => navigate(OUR_INSTITUTES_ROUTE.path)} color="inherit">Our Institutes</Button>
                  <Button onClick={() => navigate(MOCK_TEST_ROUTE.path)} color="inherit">Mock Tests</Button>
                  <Button onClick={() => navigate(EXAM_DETAILS_ROUTE.navigate('assam-police-si-2023'))} color="inherit">Assam Police SI</Button>
                  <Button onClick={() => navigate(EXAM_DETAILS_ROUTE.navigate('assam-direct-recruitment-exam-2023'))} color="inherit">Assam Direct Recruitment Exam</Button>
                  {/* <Button onClick={() => navigate(MOCK_TEST_ROUTE.path)} color="inherit">Jee Mains</Button> */}
                  {/* <Button onClick={() => navigate(MOCK_TEST_ROUTE.path)} color="inherit">Jee Advance</Button> */}
                  {/* <Button color="inherit">Competitive exams</Button> */}
                  {/* <Button color="inherit">Our Partners</Button> */}
                  <Button onClick={() => navigate(CONTACT_US_ROUTE.path)} color="inherit">Contact Us</Button>
                  {/* <Button color="inherit">Connect with Experts</Button> */}
                  {/* <Button color="inherit">Opportunities</Button> */}
                  {/* <Button onClick={() => navigate(WORK_WITH_US_ROUTE.path) } color="inherit">Work with us</Button> */}
                  {/* <Button color="inherit">FAQs</Button> */}
                  <Button onClick={() => navigate(ABOUT_US_ROUTE.path)} color="inherit">About Us</Button>
                </div>

                {
                  !isLoggedIn &&
                  <div>
                    <Button onClick={() => setLoginDialogOpen(true)} style={{marginLeft: '14px'}} variant="contained" color="primary" className="white-text">
                        Login
                    </Button>
                    {
                      !isMobile &&
                      <Button onClick={() => setSignupDialogOpen(true)} style={{marginLeft: '14px'}} variant="contained" color="secondary" className="white-text">
                          Signup
                      </Button>
                    }
                    
                  </div>
                }
                
                {
                  isLoggedIn &&
                  <div>
                    <IconButton onClick={handleProfileDropdownClick} color="primary">
                      <Avatar className={classes.userAvatar}>{getInitials(userName)}</Avatar>
                      <ArrowDropDownIcon/>
                    </IconButton>

                    <div>
                      <StyledMenu
                        id="customized-menu"
                        anchorEl={profileAnchorEl}
                        keepMounted
                        open={Boolean(profileAnchorEl)}
                        onClose={() => setProfileAnchorEl(null)}
                        className={classes.dropdownMenu}
                      >
                        <div className={classes.dropdownWelcome}>
                          Hi {userName || 'User'},
                        </div>

                        <StyledMenuItem onClick={handleLogout}>
                          <ListItemText primary="Logout" />
                          <ListItemIcon>
                            <ExitToAppIcon fontSize="small" />
                          </ListItemIcon>
                        </StyledMenuItem>
                        
                      </StyledMenu>
                    </div>
                  </div>
                  
                }
                
                
              </Toolbar>
          </AppBar>

          {/* REsponsive Side drawer */}
          <SideDrawer 
            isOpen={openSideDrawer} 
            handleClose={ () => setOpenSideDrawer(false) } 
            onLoginClick={() => setLoginDialogOpen(true)}
            onSignupClick={() => setSignupDialogOpen(true)}
          />    
          
          {/* Login Dialog */}
          <LoginDialog open={loginDialogOpen} handleClose={handleLoginDialogClose} addAlert={addAlert}  />

          {/* Signup Dialog */}
          <SignupDialog open={signupDialogOpen} handleClose={() => setSignupDialogOpen(false)} addAlert={addAlert} />

          {/* Subscription dialog */}
          <SubscriptionDialog open={subscriptionDialogOpen} handleOpen={handleSubscriptionDialogOpen} handleClose={handleSubscriptionDialogClose} addAlert={addAlert} />
        </div>
    );
}

const mapState = (state: RootState) => {
  return {
    alerts: state.alert.alerts,
  };
};

const mapDispatch = {
  addAlert
};

export const HeaderContainer = connect(mapState, mapDispatch)(Header);
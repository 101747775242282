import React from 'react'
import styled from 'styled-components'



const StyledSpinner = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before{
    content: "";
    position: fixed;
    background-color: rgba(0, 0, 0, 0.20);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 100;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
  }

  div{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #d7cfcd;
    border-left-color: #F44E03;
    display: inline-block;
    margin: 0 auto;
    animation: roundSpin .6s linear infinite;
    z-index: 100;
  }
`

export const Spinner = ()=> (
  <StyledSpinner>
    <div></div>
	</StyledSpinner>
);

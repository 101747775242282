import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useDeviceDetector } from '../../hooks/useDeviceDetector';
import clsx from 'clsx';
import { RootState } from '../../stores/rootReducer';
import { connect } from 'react-redux';
import { removeAlert, clearAllAlerts } from '../../stores/Alert/Actions';

const ALERT_TIMEOUT_DEFAULT = 4000;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      maxWidth: '400px',
      position: 'absolute',
      right: '10px',
      top: '74px',
      zIndex: 9999,
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    isMobile: {
      width: "80%"
    }
  }),
);

const Alerts = ({ alerts, removeAlert, clearAllAlerts }: any) => {
  const classes = useStyles();
  const { isMobile } = useDeviceDetector();

  useEffect(() => {
    for (const alert of alerts) {
      const timeout = setTimeout(() => {
        removeAlert(alert);
        clearTimeout(timeout);
      }, alert.timeout || ALERT_TIMEOUT_DEFAULT)
    }
  }, [alerts]);

  return (
    <div className={clsx(classes.root, { [classes.isMobile]: isMobile })}>
      {
        alerts.map((alert: any) => 
          <Alert key={alert.id} variant="filled" severity={alert.type}>
            {alert.message}
          </Alert>
        )
      }
    </div>
  );
}

const mapState = (state: RootState) => {
  return {
    alerts: state.alert.alerts,
  };
};

const mapDispatch = {
  removeAlert,
  clearAllAlerts,
};

export const AlertContainer = connect(mapState, mapDispatch)(Alerts);
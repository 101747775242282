import React from "react";
import { LINEAR_LOADER_CONTAINER_ID_NAME } from "../../constants/app.constant";
import "./linearLoader.scss";

export const LinearLoader = () => {

  return (
    <div id={LINEAR_LOADER_CONTAINER_ID_NAME} className="loader-container">
      <div className="loader-left">
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      </div>

      <div className="loader-right">
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      </div>
    </div>
  )
}
import { SET_USER, UPDATE_USER } from "./Types";
import { storageService } from "../../services";
import createApiReducer from '../Api/Reducer';
import { combineReducers } from 'redux';
import { createSlice, createReducer } from '@reduxjs/toolkit';
import { setUser, updateUser, setUserProfile } from './Actions';

interface IUserState {
  user: any;
  profile: any;
}

const initialState = { } as IUserState;

const userReducer = createReducer(
  initialState, 
  (builder) => {
    builder
      .addCase(setUser, (state, action) => action.payload)
      .addCase(updateUser, (state, action) => action.payload)
  }
);

const userProfileReducer = createReducer(
  initialState, 
  (builder) => {
    builder
      .addCase(setUserProfile, (state, action) => action.payload)
  }
);

export default combineReducers({
  user: userReducer,
  profile: userProfileReducer,
});






// export const userProfileReducerName = 'userProfile';

// const user = createApiReducer(userReducerName, {'asas': 'qq'});  //Use this if we have api based reducer
// const user = createSlice({
//   name: userProfileReducerName,
//   initialState: {},
//   reducers: {
//     [`${userProfileReducerName}PlainReducer`]: (state: any, action) => {
//       console.log('asasa', {state, action})
//       return {
//         data: action.payload,
//       }
//     }
//   }
// });

// export const userActions = {
//   ...user.actions,
// };
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import signupBanner from '../../../assets/images/auth/login-banner.png'
import { axiosService, initPaymentRazorpay, linearLoaderService } from '../../services';
import CheckIcon from '@material-ui/icons/Check';
import { useDeviceDetector } from '../../hooks/useDeviceDetector';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import './styles.scss';

interface ISubscriptionDialog {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  addAlert: any,
}

export const SubscriptionDialog = ({ open, handleOpen, handleClose, addAlert }: ISubscriptionDialog) => {
  const { isMobile, isTablet } = useDeviceDetector();

  const [subscriptionSuccess, setSubsciptionSuccess] = useState(false);

  const handleStartSubscription = async () => {
    const { data: { data: user } } = await axiosService.setAuth(true).get('user/user/profile');
    handleClose();

    initPaymentRazorpay(99, user).then(async (res: any) => {
      await axiosService.setAuth(true).post('user/user/upgradeUserPlan', { ...res } );
      handleOpen()
      setSubsciptionSuccess(true);
    });
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" className="subscription-dialog-container" aria-labelledby="form-dialog-title">
        <DialogContent className="dialog-content">
          <Grid container spacing={0} className="grid-container">
            <Grid item md={12} lg={6} className="banner-container">
              {
                !isMobile &&
                <Typography component="p" className="primary-color auth-banner-heading">Enroll Now & <br/>Start Preparing</Typography>
              }
              {
                isMobile && !subscriptionSuccess &&
                <Typography component="p" className="primary-color auth-banner-heading">Subscribe now</Typography>
              }

              {
                !isMobile &&
                <div className="banner-img-container">
                  <img src={signupBanner} alt="login-banner" />
                </div>
              }
              
            </Grid>
            <Grid item md={12} lg={6} className="subscription-form-container">
              {
                !isMobile && !subscriptionSuccess &&
                <Typography component="p" className="primary-color auth-heading">Subscribe now</Typography>
              }
              { !subscriptionSuccess &&
                <div>
                  <Typography component="p" className="auth-slogan">
                    Offer at <span className="offer-pricetag"><s>299</s> <strong>₹ 99 </strong></span> only <br/>Subscription plan includes <br/>
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon className="sub-icon" />
                      </ListItemIcon>
                      <ListItemText primary="Attempt unlimited tests for a month" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon className="sub-icon" />
                      </ListItemIcon>
                      <ListItemText primary="Overall and Test specific Performance Analytics" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon className="sub-icon" />
                      </ListItemIcon>
                      <ListItemText primary="Improvement History" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon className="sub-icon" />
                      </ListItemIcon>
                      <ListItemText primary="Compete with other candidates and know you position" />
                    </ListItem>
                  </List>
                </div>
                
              }

              {
                subscriptionSuccess &&
                <div className='text-center subscription-success'>
                  <DoneOutlineIcon className="primary-color subscription-success-icon" />
                  <Typography component="h4" className="primary-color subscription-success-message">Subscription has started!</Typography>
                </div>
              }
              {
                !subscriptionSuccess &&
                <Button 
                  variant="contained" size="medium" 
                  color="primary" className="white-text login-btn"
                  onClick={handleStartSubscription}>
                    Start Subscription at ₹ 99
                </Button>
              }

              {
                subscriptionSuccess &&
                <Button 
                  variant="contained" size="medium" 
                  color="primary" className="white-text login-btn"
                  onClick={handleClose}>
                    Start attempting tests
                </Button>
              }
            </Grid>
          </Grid>
         
        </DialogContent>
      </Dialog>
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Typography, IconButton } from '@material-ui/core';
import signupBanner from '../../../assets/images/auth/login-banner.png'
import './signupDialog.scss';
import { authService, axiosService, linearLoaderService } from '../../services';
import { OtpInput } from '../OtpInput/OtpInput';
import { CAPTCHA_CONTAINER_ID } from '../../constants/app.constant';
import { formatSecsToMMSS } from '../../utilities';
import { useTimer } from 'use-timer';
import { useDeviceDetector } from '../../hooks/useDeviceDetector';
import BackIcon from '@material-ui/icons/ArrowBack';

interface ISignupDialog {
  open: boolean;
  handleClose: () => void;
  addAlert: any,
}

export const SignupDialog = ({ open, handleClose, addAlert }: ISignupDialog) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState<any>('');
  const [otpToken, setOtpToken] = useState('');
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation]: any = useState(null);
  const [error, setError]: any = useState(null);
  const resendOtpTimer = useTimer({
    initialTime: 20,
    endTime: 0,
    timerType: 'DECREMENTAL',
  });
  const { isMobile, isTablet } = useDeviceDetector();

  const handleMobileNumberChange = (e: any) => {
    const value = e.target.value;
    console.log({value})
    setPhoneNumber(value);
    setShowCaptcha(false);
  };

  const requestOtp = async () => {
    if (phoneNumber?.length < 10 || !phoneNumber?.match(/^[6-9]\d{9}$/)) {
      addAlert({ message: "Invalid number entered! Hint: Try without +91", type: "warning" });
      return
    }

    setLoading(true);    
    linearLoaderService.showLoader();
    const response = await authService.requestOtp({ mobile: phoneNumber });
    linearLoaderService.hideLoader();
    setOtpToken(response?.data?.otpToken);
    setLoading(false);
    setIsOtpSent(true);
    resendOtpTimer.reset();
    resendOtpTimer.start();
  };

  const verifyOtp = async () => {
    linearLoaderService.showLoader();

    console.log('=======>', { phoneNumber, otp, otpToken })

    const response = await authService.verifyOtp({ mobile: phoneNumber, otp, otpToken })

    if (response?.data?.authenticated) {
      setLoading(false);
      authService.authSuccess({ accessToken: response?.data?.accessToken })
      linearLoaderService.hideLoader();
      window.location.reload();
    } else {
      setLoading(false);
      linearLoaderService.hideLoader();
      console.log(response?.error?.response?.data?.message)
      const message = response?.error?.response?.data?.message || 'Some error occured! Try again.'
      addAlert({message, type: "warning"});
    }
  };

  const resendOtp = () => {
    requestOtp();
  }

  const handleReEnterMobile = () => {
    setIsOtpSent(false)
  }

  const handleModalClose = () => {
    handleClose();
    setTimeout(() => setIsOtpSent(false), 400);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleModalClose} maxWidth="md" className="signup-dialog-container" aria-labelledby="form-dialog-title">
        <DialogContent className="dialog-content">
          <Grid container spacing={0} className="grid-container">
            <Grid item md={12} lg={6} className="banner-container">
              {
                !isMobile &&
                <Typography component="p" className="primary-color auth-banner-heading">Enroll Now & <br/>Start Preparing</Typography>
              }
              {
                isMobile && isOtpSent &&
                <IconButton className="backButton absolute" aria-label="delete" size="small" onClick={handleReEnterMobile} >
                    <BackIcon fontSize="inherit" className="social-icon" />
                    Back
                </IconButton>
              }
              {
                isMobile &&
                <Typography component="p" className="primary-color auth-banner-heading">Signup</Typography>
              }
              <div className="banner-img-container">
                <img src={signupBanner} alt="login-banner" />
              </div>
            </Grid>
            <Grid item md={12} lg={6} className="login-form-container">
              {
                !isMobile && isOtpSent &&
                <IconButton className="backButton floatLeft" aria-label="delete" size="small" onClick={handleReEnterMobile}>
                    <BackIcon fontSize="inherit" className="social-icon" />
                    Back
                </IconButton>
              }
              {
                !isMobile &&
                <Typography component="p" className="primary-color auth-heading">Signup</Typography>
              }
              {
                !isMobile &&
                <Typography component="p" className="auth-slogan">
                  Hey new User 🎈 <br/>
                  We recommend you take as many test as you can to achieve your desired goal.
                </Typography>
              }
              {
                !isOtpSent &&
                <div className="mobile-number" >
                  <TextField
                    autoFocus
                    margin="dense"
                    id="mobile"
                    label="Enter your phone number"
                    type="text"
                    fullWidth
                    value={phoneNumber}
                    onChange={handleMobileNumberChange}
                  />
                </div>
              }
              
              <div id={CAPTCHA_CONTAINER_ID} className={`captcha-container ${showCaptcha && !isOtpSent ? '' : 'hidden'}`}/>

              {
                isOtpSent &&
                <OtpInput
                  autoFocus
                  isNumberInput
                  length={6}
                  className="otpContainer"
                  inputClassName="otpInput"
                  onChangeOTP={setOtp}
                />
              }
              {/* <CircularProgress color="secondary" /> */}

      
              {
                !showCaptcha && !isOtpSent && 
                <Button 
                  variant="contained" size="medium" 
                  color="primary" className="white-text login-btn"
                  disabled={loading}
                  onClick={requestOtp}>
                    Sign Up
                </Button>
              }

              {
                isOtpSent &&
                <Button 
                  variant="contained" size="medium" 
                  color="primary" className="white-text login-btn"
                  disabled={otp.length !== 6}
                  onClick={verifyOtp}>
                    SUBMIT
                </Button>
              }

{
                isOtpSent &&
                <div className="resend-otp-container">
                  {

                  }
                  <div className='otp-timer'>
                    { 
                      resendOtpTimer.time === 0 &&
                      `Didn't receive OTP?`
                    }
                    {
                      resendOtpTimer.time !== 0 &&
                      formatSecsToMMSS(resendOtpTimer.time)
                    }
                  </div>

                  <Button
                    disabled={resendOtpTimer.time !== 0 || loading}
                    size="medium"
                    color="primary" className="resend-otp-btn"
                    onClick={resendOtp}>
                      Resend OTP
                  </Button>
                </div>
              }
              
            </Grid>
          </Grid>
         
        </DialogContent>
      </Dialog>
    </div>
  );
}


// firebaseConfig: {
//   apiKey: "AIzaSyCxZQzTQg_Nselj_gi6eFSWlltT0r22Euo",
//   authDomain: "xarothi-3010a.firebaseapp.com",
//   databaseURL: "https://xarothi-3010a.firebaseio.com",
//   projectId: "xarothi-3010a",
//   storageBucket: "xarothi-3010a.appspot.com",
//   messagingSenderId: "576056012448",
//   appId: "1:576056012448:web:d445cf37180b270ba1a3c5",
//   measurementId: "G-FM4YB7H05Y"
// }
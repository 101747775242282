import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Spinner } from './components';
import { withAuth } from './hocs/withAuth';
import { connect } from 'react-redux';
import { setUser, setUserProfile } from './stores/User/Actions';
import { RootState } from "./stores/rootReducer";
import {
  HOME_ROUTE,
  TEST_ATTEMPT_ROUTE,
  MOCK_TEST_ROUTE,
  DASHBOARD_ROUTE,
  ONBOARDING_ROUTE,
  TEST_RESULT_ROUTE,
  TEST_ATTEMPT_PREVIEW_ROUTE,
  EXAM_DETAILS_ROUTE,
  WORK_WITH_US_ROUTE,
  BECOME_A_MENTOR_ROUTE,
  ABOUT_US_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
  REFUND_AND_CANCELLATION_ROUTE,
  CONTACT_US_ROUTE,
  TEST_PREPARATION_MODE_ROUTE,
  OUR_INSTITUTES_ROUTE,
} from '../app/constants/route.constant';

const HomeScreen = React.lazy(() =>
  import('./screens/HomeScreen/HomeScreenContainer'),
);

const TestAttemptScreen = React.lazy(() =>
  import('./screens/TestAttemptScreen/TestAttemptScreenContainer'),
);

const MockTestScreen = React.lazy(() =>
  import('./screens/MockTestsScreen/MockTestsScreenContainer'),
);

const DashboardScreen = React.lazy(() =>
  import('./screens/DashboardScreen/DashboardScreenContainer'),
);

const OnboardingScreen = React.lazy(() =>
  import('./screens/OnboardingScreen/OnboardingScreenContainer'),
);

const TestResultScreen = React.lazy(() =>
  import('./screens/TestResultScreen/TestResultScreenContainer'),
);

const TestAttemptPreviewScreen = React.lazy(() =>
  import('./screens/TestAttemptPreviewScreen/TestAttemptPreviewScreenContainer'),
);

const ExamDetailsScreen = React.lazy(() =>
  import('./screens/ExamDetailsScreen/ExamDetailsScreenContainer'),
);

const WorkWithUsScreen = React.lazy(() =>
  import('./screens/WorkWithUsScreen/WorkWithUsScreenContainer'),
);

const BecomeAMentorScreen = React.lazy(() =>
  import('./screens/BecomeAMentorScreen/BecomeAMentorScreenContainer'),
);

const AboutUsScreen = React.lazy(() =>
  import('./screens/AboutUsScreen/AboutUsScreenContainer'),
);

const PrivacyPolicyScreen = React.lazy(() =>
  import('./screens/PrivacyPolicy/PrivacyPolicyScreenContainer'),
);

const TermsAndConditionsScreen = React.lazy(() =>
  import('./screens/TermsAndConditions/TermsAndConditionsScreenContainer'),
);

const RefundAndCancellationScreen = React.lazy(() =>
  import('./screens/RefundAndCancellation/RefundAndCancellationScreenContainer'),
);

const ContactUsScreen = React.lazy(() =>
  import('./screens/ContactUs/ContactUsScreenContainer'),
);

const TestPreparationModeScreen = React.lazy(() =>
  import('./screens/TestPreparationModeScreen/TestPreparationModeScreenContainer'),
);

const OurInstitutesScreen = React.lazy(() =>
  import('./screens/OurInstitutes/OurInstitutesScreenContainer'),
);

const AppRouter = (props: any): JSX.Element => {
  return (
        <Router>
          <React.Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path={HOME_ROUTE.path}>
                <HomeScreen />
              </Route>
              <Route exact path={TEST_ATTEMPT_ROUTE.path}>
                <TestAttemptScreen />
              </Route>
              <Route exact path={MOCK_TEST_ROUTE.path}>
                <MockTestScreen />
              </Route>
              <Route exact path={DASHBOARD_ROUTE.path}>
                <DashboardScreen />
              </Route>
              <Route exact path={ONBOARDING_ROUTE.path}>
                <OnboardingScreen />
              </Route>
              <Route exact path={TEST_RESULT_ROUTE.path}>
                <TestResultScreen />
              </Route>
              <Route exact path={TEST_ATTEMPT_PREVIEW_ROUTE.path}>
                <TestAttemptPreviewScreen />
              </Route>
              <Route exact path={EXAM_DETAILS_ROUTE.path}>
                <ExamDetailsScreen />
              </Route>
              <Route exact path={WORK_WITH_US_ROUTE.path}>
                <WorkWithUsScreen />
              </Route>
              <Route exact path={BECOME_A_MENTOR_ROUTE.path}>
                <BecomeAMentorScreen />
              </Route>
              <Route exact path={ABOUT_US_ROUTE.path}>
                <AboutUsScreen />
              </Route>
              <Route exact path={PRIVACY_POLICY_ROUTE.path}>
                <PrivacyPolicyScreen />
              </Route>
              <Route exact path={TERMS_AND_CONDITIONS_ROUTE.path}>
                <TermsAndConditionsScreen />
              </Route>
              <Route exact path={REFUND_AND_CANCELLATION_ROUTE.path}>
                <RefundAndCancellationScreen />
              </Route>
              <Route exact path={CONTACT_US_ROUTE.path}>
                <ContactUsScreen />
              </Route>
              <Route exact path={TEST_PREPARATION_MODE_ROUTE.path}>
                <TestPreparationModeScreen />
              </Route>
              <Route exact path={OUR_INSTITUTES_ROUTE.path}>
                <OurInstitutesScreen />
              </Route>
              <Route path={'*'}>
                <Redirect to="/" />
              </Route>
            </Switch>
          </React.Suspense>
        </Router>
  );
}

const mapState = (state: RootState) => {
  return {
    user: state.user.user,
    profile: state.user.profile
    // networkActivity: state.networkActivity,
  };
};

const mapDispatch = {
  setUser,
  setUserProfile,
};

const connector = connect(mapState, mapDispatch)(withAuth(AppRouter));
export default connector;
import React from "react";
import { MathJaxContext, MathJax as MathJaxRender } from "better-react-mathjax";
import './style.scss';

export const MathJax = ({ data }: any) => {

  return (
    <div className="mathjax"> 
      {
        data &&
        <MathJaxContext>
          <MathJaxRender dangerouslySetInnerHTML={{ __html: data }}></MathJaxRender>
        </MathJaxContext>
      }
    </div>
  )
}
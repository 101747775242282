export enum ROUTE_TYPE {
  public,
  private,
}
interface ROUTE {
  path: string;
  type: ROUTE_TYPE;
  navigate?: any;
}

export const HOME_ROUTE: ROUTE = { 
  path: '/', 
  type: ROUTE_TYPE.public 
};

export const TEST_ATTEMPT_ROUTE: ROUTE = {
  path: '/test-attempt/:attemptId',
  type: ROUTE_TYPE.private,
};

export const MOCK_TEST_ROUTE: ROUTE = {
  path: '/mock-tests',
  type: ROUTE_TYPE.private,
};

export const DASHBOARD_ROUTE: ROUTE = {
  path: '/dashboard',
  type: ROUTE_TYPE.private,
};

export const ONBOARDING_ROUTE: ROUTE = {
  path: '/onboarding',
  type: ROUTE_TYPE.private,
};

export const TEST_RESULT_ROUTE: ROUTE = {
  path: '/test-result/:attemptId',
  type: ROUTE_TYPE.private,
  navigate: (attemptId: string) => `/test-result/${attemptId}`,
};

export const TEST_ATTEMPT_PREVIEW_ROUTE: ROUTE = {
  path: '/test-attempt-preview/:attemptId',
  type: ROUTE_TYPE.private,
  navigate: (attemptId: string) => `/test-attempt-preview/${attemptId}`,
};

export const EXAM_DETAILS_ROUTE: ROUTE = {
  path: '/exam-details/:examIdentifier',
  type: ROUTE_TYPE.public,
  navigate: (examIdentifier: string) => `/exam-details/${examIdentifier}`,
};

export const WORK_WITH_US_ROUTE: ROUTE = {
  path: '/work-with-us',
  type: ROUTE_TYPE.public,
};

export const BECOME_A_MENTOR_ROUTE: ROUTE = {
  path: '/become-a-mentor',
  type: ROUTE_TYPE.public,
};

export const ABOUT_US_ROUTE: ROUTE = {
  path: '/about-us',
  type: ROUTE_TYPE.public,
};

export const PRIVACY_POLICY_ROUTE: ROUTE = {
  path: '/privacy-policy',
  type: ROUTE_TYPE.public,
};

export const TERMS_AND_CONDITIONS_ROUTE: ROUTE = {
  path: '/terms-and-conditions',
  type: ROUTE_TYPE.public,
};

export const REFUND_AND_CANCELLATION_ROUTE: ROUTE = {
  path: '/refund-and-cancellation',
  type: ROUTE_TYPE.public,
};

export const CONTACT_US_ROUTE: ROUTE = {
  path: '/contact-us',
  type: ROUTE_TYPE.public,
};

export const TEST_PREPARATION_MODE_ROUTE: ROUTE = {
  path: '/test-preparation-mode/:testId',
  type: ROUTE_TYPE.private,
  navigate: (testId: string) => `/test-preparation-mode/${testId}`,
};

export const OUR_INSTITUTES_ROUTE: ROUTE = {
  path: '/our-institutes',
  type: ROUTE_TYPE.public,
};
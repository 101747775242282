import { useMediaQuery } from "@material-ui/core";
import React, { useState, useEffect } from "react";

export const useDeviceDetector = () => {
  // const [isMobile, setMobile] = useState(false);
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isMobile = useMediaQuery('(max-width: 425px)');

  // useEffect(() => {
  //   const userAgent =
  //     typeof window.navigator === "undefined" ? "" : navigator.userAgent;
  //   const mobile = Boolean(
  //     userAgent.match(
  //       /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  //     )
  //   );
  //   setMobile(mobile);
  // }, []);

  const isRunningLocally = window.location.href.includes('localhost')

  return { isMobile, isTablet, isRunningLocally };
}
import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './styles'
import appStore from "./stores";
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import { Alerts, LinearLoader } from './components';
import './App.scss';

const App = (props: any): JSX.Element => {
  return (
    <Provider store={appStore}>
      <ThemeProvider theme={theme}>
        <LinearLoader />
        <Alerts />
        <AppRouter />
      </ThemeProvider>
    </Provider>
    
  );
}
export default App;

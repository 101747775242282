import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { addAlert, removeAlert, clearAllAlerts } from './Actions';
import { v4 as uuidv4 } from 'uuid';


const initialState = [] as any[];

const ALERT_LIMIT = 3;

const alertReducer = createReducer(
  initialState, 
  (builder) => {
    builder
      .addCase(addAlert, (state, action) => {
        const { payload } = action;
        payload.id = uuidv4();
        const result = [...state, payload];
        if (result.length > ALERT_LIMIT) {
          return result.slice(result.length - ALERT_LIMIT, result.length);
        }
        return result;
      })
      .addCase(removeAlert, (state, action) => {
        return state.filter(item => item.id !== action.payload.id);
      })
      .addCase(clearAllAlerts, (state, action) => [])
  }
);

export default combineReducers({
  alerts: alertReducer,
});
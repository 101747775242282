import { ADD_ALERT, REMOVE_ALERT, CLEAR_ALL_ALERTS } from "./Types";
import { createAction } from '@reduxjs/toolkit';

/**
 * Alert payload
 *  - id
 *  - message
 *  - type: error | warning | info | success
 *  - timeout: optional in ms 4000s by default
 */

export const addAlert = createAction(
  ADD_ALERT,
  function prepare(payload) {
    return {
      payload,
    };
  },
);

export const removeAlert = createAction(
  REMOVE_ALERT,
  function prepare(payload) {
    return {
      payload,
    };
  },
);

export const clearAllAlerts = createAction(
  CLEAR_ALL_ALERTS,
  function prepare(payload) {
    return {
      payload,
    };
  },
);
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import logo from '../../../assets/images/logo.png';
import { useHistory } from 'react-router';
import { ABOUT_US_ROUTE, CONTACT_US_ROUTE, EXAM_DETAILS_ROUTE, HOME_ROUTE, MOCK_TEST_ROUTE, OUR_INSTITUTES_ROUTE, WORK_WITH_US_ROUTE } from '../../constants/route.constant';
import { authService } from '../../services';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    width: '68px',
    cursor: 'pointer',
  },
  title: {
    flexGrow: 1,
  },
  headerNav: {
    display: 'flex',
    
  },
  headerNavButtons: {
    paddingTop: '20px',
    paddingRight: '9px'
  },
  userName: {
    color: '#03A9F4'
  },
}));

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const SideDrawer = ({ isOpen, handleClose, onLoginClick, onSignupClick }: any) => {
  const classes = useStyles();
  const anchor: Anchor = 'left';
  const history = useHistory();
  const { isLoggedIn, userName } = authService;

  const handleLogoClick = () => {
    history.push(HOME_ROUTE.path);
  }

  const navigate = (url: string) => {
    history.push(url);
  }

  const handleLogout = () => {
    authService.signOut();
    history.push('/')
  }

  return (
    <Drawer anchor={anchor} open={isOpen} onClose={handleClose}>
      <div
        className={clsx(classes.list)}
        role="presentation"
        onClick={handleClose}
        onKeyDown={handleClose}
      >
        <div className={classes.headerNav}>
          <img onClick={handleLogoClick} className={classes.logo} src={logo} alt="logo" />
            <Typography variant="h6" className={classes.title}>
          </Typography>
          <div className={classes.headerNavButtons}>
            {
              !isLoggedIn && 
              <div>
                <Button onClick={onLoginClick} size="small" variant="contained" color="primary" className="white-text">
                    Login
                </Button>
                <Button onClick={onSignupClick} style={{marginLeft: '14px'}} size="small" variant="contained" color="secondary" className="white-text">
                    Signup
                </Button>
              </div>
            }

            {
              isLoggedIn &&
              <div className={classes.userName}>Hey {userName || 'User'}!</div>
            }
          </div>
        </div>
        <br/>

        <List>
          <ListItem button onClick={() => navigate(MOCK_TEST_ROUTE.path)}>
            <ListItemText primary='Mock Tests' />
          </ListItem>
          <ListItem button onClick={() => navigate(EXAM_DETAILS_ROUTE.navigate('assam-police-si-2023'))}>
            <ListItemText primary='Assam Police SI' />
          </ListItem>
          <ListItem button onClick={() => navigate(EXAM_DETAILS_ROUTE.navigate('assam-direct-recruitment-exam-2023'))}>
            <ListItemText primary='Assam Direct Recruitment Exam' />
          </ListItem>
          {/* <ListItem button onClick={() => navigate(MOCK_TEST_ROUTE.path)}>
            <ListItemText primary='Jee Advance' />
          </ListItem> */}
          {/* <ListItem button>
            <ListItemText primary='Connect with Experts' />
          </ListItem> */}
          <ListItem button onClick={() => navigate(OUR_INSTITUTES_ROUTE.path)}>
            <ListItemText primary='Our Institutes' />
          </ListItem>
          <ListItem button onClick={() => navigate(CONTACT_US_ROUTE.path)}>
            <ListItemText primary='Contact Us' />
          </ListItem>
          <ListItem button onClick={() => navigate(ABOUT_US_ROUTE.path) }>
            <ListItemText primary='About Us' />
          </ListItem>
          {/* <ListItem button>
            <ListItemText primary='Opportunities' />
          </ListItem> */}
          <ListItem button onClick={() => navigate(WORK_WITH_US_ROUTE.path) }>
            <ListItemText primary='Work with us' />
          </ListItem>
          <ListItem button>
            <ListItemText primary='FAQs' />
          </ListItem>
          {/* <ListItem button>
            <ListItemText primary='Contact Us' />
          </ListItem> */}
        </List>
        <Divider />
        <List>
          
          {
            isLoggedIn &&
            <ListItem button onClick={handleLogout}>
              <ListItemIcon><InboxIcon /></ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItem>
          }
          
        </List>
      </div>
    </Drawer>
  );
}
